<template>
  <div class="fuwu">
    <div class="img">
      <img src="../../../../assets/imgs/chanping/shebei/107.png" alt="" />
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      功能
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">水务综合监测预警</div>
      <div class="li-text">
        实现对水文、水质等进行自动、准确地监测，数据远程自动传输
        <br />实现水文、水质等信息在线查询、分析、显示、打印
        <br />当水文、水质相关值达到预警阀值可自动进行预警，同时启动应急预案
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">城市供水智能化管理</div>
      <div class="li-text">
        供水信息传感监测 <br />供水综合监管 <br />供水移动业务办公
        <br />供水工程监管 <br />供水水质监测 <br />供水巡检养护管理
        <br />供水抢险应急 <br />供水综合运营 <br />供水客服管理
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">水资源管理</div>
      <div class="li-text">
        水资源开发利用总量分析 <br />用水效率控制分析 <br />水功能区限制纳污分析
        <br />产业调整分析 <br />泵闸联合调度
      </div>
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">城市排水信息管理</div>
      <div class="li-text">
        排水信息传感监测 <br />排水设施养护监管 <br />排水水质监测管理
        <br />排水户管理 <br />模型分析
      </div>
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">城市内涝监控预警</div>
      <div class="li-text">
        内涝监测预警 <br />内涝监测预警 <br />服务查询 <br />内涝模拟
        <br />应急调度
      </div>
    </div>
    <div class="biaoti mt30">
      <span class="el-icon-caret-bottom"></span>
      应用场景
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">供水管网地理信息</div>
      <div class="li-text">
        采用GIS方式实现对供水管网系统中所有管线、设备、构筑物（水池、水塔等）空间位置信息、属性信息（如埋深、材质、年代、口径、连接、用途等）进行统一的数据管理。
      </div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/108.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">监控数据Web图表展示</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/109.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">监控数据地图展示</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/110.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">信息统计分析</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/111.png" alt="" />
    </div>
    <div class="img mt10">
      <img src="../../../../assets/imgs/chanping/shebei/112.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">夜间最小流量分析</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/113.png" alt="" />
    </div>

    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">水量平衡分析</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/114.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">各分区产销差分析</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/115.png" alt="" />
    </div>
    <div class="li mt20">
      <div class="dian"></div>
      <div class="li-title">实时监控并告警</div>
    </div>
    <div class="img mt30">
      <img src="../../../../assets/imgs/chanping/shebei/116.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.scrollTop = 0;
  },
};
</script>

<style lang="scss" scoped>
.fuwu {
  width: 870px;
  padding-bottom: 60px;

  .img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
    }
  }
  .jieshao {
    font-size: 16px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #181818;
    margin-top: 20px;
  }
  .biaoti {
    font-size: 13px;
    font-family: Alibaba PuHuiTi;
    font-weight: 400;
    line-height: 17px;
    color: #181818;
    display: flex;
    align-items: center;
    margin-left: -6px;
    span {
      margin-right: 8px;
      font-size: 20px;
    }
  }
  .text {
    font-size: 13px;
    font-family: Alibaba PuHuiTi Xi;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
    margin-top: 20px;
  }
  .li {
    width: 100%;
    position: relative;
    padding-left: 14px;
    .dian {
      width: 6px;
      height: 6px;
      background: rgba(68, 112, 255, 1);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 0;
    }
    .li-title {
      font-size: 14px;
      font-family: Alibaba PuHuiTi;
      font-weight: 700;
      line-height: 22px;
      color: #666666;
    }
    .li-text {
      font-size: 13px;
      font-family: Alibaba PuHuiTi Xi;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
    }
  }
}
</style>